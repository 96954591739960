.App {
  text-align: center;

}



::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 1171px) {
  .eventListe {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 70%;
    min-width: 68%;
  }

  .img_BK {
    height: 10em !important;

  }

  .logo {
    height: 100em !important;
  }



}